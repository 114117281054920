import { store } from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class JournalReportServices {
  
    getJournalReportFilterDatas(param) {
        const url = `api/account-report/journal-report/filter`
        const params = {
            'page-index': param.pageIndex,
            'branch': param.branch,
            'today': param.today,
            'month': param.month,
            'year': param.year,
            'from': param.from_date,
            'to': param.to_date,
            'page-reload': param.page_reload,
            'fiscal-year': param.fiscal_year,
            'model':param.model,
            'offset':param.offset,
            'searched':param.searched

        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    getUserAccessBranches() {
        const url = `api/sales/summary/user/branches`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }
  
  

}

export default new JournalReportServices();
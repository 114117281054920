<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Journal</a>
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Journal</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            v-if="filterParams.model"
            class="mg-t-5 mr-2"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="">All</option>
          </select>
          <select
            class="mg-t-5 mr-2"
            v-model="filterParams.fiscal_year"
            @change="getFilteredDataWithoutDates"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mg-t-5 mr-2"
            @change="selectBranch"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            class="mg-t-5 mr-2"
            v-model="filterParams.model"
            @change="selectModel"
          >
            <option value="">All Transactions</option>
            <option
              v-for="(model, index) in model"
              :key="index"
              :value="model.slug"
            >
              {{ model.name }}
            </option>
          </select>

          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              @input="selectFromDate"
              placeholder="From"
              class="filter-input mg-t-5 mr-1"
            />
            <label class="filter-lavel mb-0 mg-t-5 mr-1">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              @input="selectEndDate"
              placeholder="To"
              class="mg-t-5 filter-input"
            />
          </div>
          <input
            type="text"
            v-model="filterParams.searched"
            @keyup="searchedKeyword"
            class="filter-input float-right mg-t-5 mr-3"
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-15p">Date</th>
              <th class="wd-60p">Description</th>
              <th class="wd-20p text-right table-end-item">Transaction</th>
            </tr>
          </thead>
          <tbody v-if="!loading && journalData.length > 0">
            <tr v-for="(journal, index) in journalData" :key="index">
              <td scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </td>
              <td>
                <div class="td-title">{{ journal.date }}</div>
              </td>
              <td>
                <div class="td-title">
                  {{ journal.description }}
                </div>
                <span class="tx-11 tx-sans tx-color-04 mg-t-2"
                  >{{ journal.description2 }}
                </span>
              </td>
              <td>
                <div class="td-title">
                  {{ journal.transaction }}
                </div>
                <span class="tx-11 tx-sans tx-color-04 mg-t-2">
                  {{ journal.transaction2 }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && journalData.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Service";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Paginate from "vuejs-paginate";
import _ from "lodash";

export default {
  components: {
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      sessionYears: ["2021-22"],
      branches: [],
      loading: true,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      errorMessage: "",
      page: 1,
      defaultPageIndex: 20,
      filterParams: {
        pageIndex: "",
        branch: "",
        sales_type: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
        model: "",
      },
      model: [
        { name: "Purchase", slug: "purchase" },
        { name: "Purchase Order", slug: "purchase_order" },
        { name: "Purchase Return", slug: "purchase_return" },
        { name: "Sale", slug: "sales" },
        { name: "Sales Order", slug: "sales_order" },
        { name: "Capital", slug: "capital" },
        { name: "Loan", slug: "loan" },
        { name: "Income", slug: "income" },
        { name: "Expense", slug: "expense" },
        { name: "Investment", slug: "investment" },
        { name: "Assets", slug: "assets" },
        { name: "Salary Payroll", slug: "salary" },
        { name: "PayIn", slug: "payin" },
        { name: "PayOut", slug: "payout" },
        { name: "Tax Payroll", slug: "tax" },
      ],
    };
  },
  computed: {
    ...mapGetters(["eventMessage", "modalId"]),
    ...mapGetters("journalReport", ["journalData"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //print page
    print(){
        window.print();
    },
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredData();
    },
    filterReportType() {
      if (this.$route.path != this.route) {
        this.$router.push(this.route);
      }
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.filterParams.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 500),

    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.defaultPageIndex = this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectModel() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      if (!this.filterParams.model) {
        this.filterParams.pageIndex = "";
      } else {
        this.filterParams.pageIndex = this.defaultPageIndex;
      }
      this.getFilteredDataWithoutDates();
    },
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        offset: this.filterParams.offset,
        fiscal_year: this.filterParams.fiscal_year,
        model: this.filterParams.model,
        page_reload: this.pageReload,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        offset: this.filterParams.offset,
        fiscal_year: this.filterParams.fiscal_year,
        model: this.filterParams.model,
        page_reload: this.pageReload,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getJournalReportFilterDatas(params)
        .then((res) => {
          this.$store.commit(
            "journalReport/setJournalData",
            res.data.data.data
          );
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
  },

  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
